<template>
  <div class="navigation" v-on:change="category">
    <div class="menus">
      <li v-for="(item, index) in category">
        <router-link to="ui">{{ item.category_name }}</router-link>
      </li>
    </div>
    <div class="menus">
      <li>
        <router-link to="pricing" class="pricing">ئالاھىدە ئەزا</router-link>
      </li>
      <li v-on:click="login" v-if="!userInfo.username">
        <div class="userContent">كىرىش</div>
      </li>
      <li :class="showUserInfo ? 'show' : ''" v-if="userInfo.username">
        <div class="userContent" @click.stop="showUserInfo = !showUserInfo">
          {{ userInfo.username }}
          <img class="avatar" :src="userInfo.useravatar" />
        </div>
        <div class="showUserInfo" v-if="showUserInfo">
          <div class="userInfo">
            <div class="avatar">
              <img class="avatar" :src="userInfo.useravatar" />
            </div>
            <div class="info">
              <span>{{ userInfo.username }}</span>
              <em>{{ userInfo.email }}</em>
              <router-link to="user/profile" class="editProfile"
                >ئارخىپ ئۆزگەرتىش</router-link
              >
            </div>
          </div>
          <hr />
          <div class="list">
            <div class="item">
              <i class="icon-like" />
              <span>ياقتۇرغانلىرىم</span>
            </div>
            <div class="item fl">
              <div>
                <i class="icon-download" />
                <span>چۈشۈرگەنلىرىم</span>
              </div>
              <div class="downCount">2/10</div>
            </div>
            <div class="item">
              <i class="icon-following" />
              <span>ئەگەشكەنلىرىم</span>
            </div>
            <div class="item">
              <i class="icon-collection" />
              <span>ساقلىۋالغىنىم</span>
            </div>
          </div>
          <hr />
          <div class="list">
            <div class="item">
              <i class="icon-uskine" />
              <span>ئۈسكىنە</span>
            </div>
            <div class="item">
              <i class="icon-vip" />
              <span>ئالاھىدە ئەزا</span>
            </div>
          </div>
          <hr />
          <div class="list nomb">
            <div class="item">
              <i class="icon-logout" />
              <span>چىكىنىش</span>
            </div>
          </div>
        </div>
      </li>
      <li>
        <router-link to="register" class="register">تىزىملىتىش</router-link>
      </li>
    </div>
  </div>
</template>

<script>
import { FlexImage, Dt } from "@/assets/flexImage.js";
import { category } from "@/api/admin/sucai";
export default {
  name: "homeHeader",

  data() {
    return {
      category: [],
      showLoginModal: false,
      userInfo: {},
      showUserInfo: false,
    };
  },
  mounted() {
    // console.log('categoryComp',this.category)
    if (this.category.length == 0) {
      category()
        .then((res) => {
          let category = JSON.parse(Dt(res.data, "abduweliMemettursun"));
          this.category = category;
          // console.log(category)
          this.$emit("formSubmit", category);
        })
        .catch((err) => {
          console.log("fucker", err);
        });
    }

    if (localStorage.getItem("xx-token")) {
      var userInfo = JSON.parse(
        Dt(localStorage.getItem("xx-token"), "abduweliMemettursun")
      );
      if (userInfo.username) {
        console.log(userInfo);
        this.userInfo = userInfo;
      }
    }

    document.addEventListener("click", this.HiddenClick);
  },

  methods: {
    HiddenClick() {
      this.showUserInfo = false;
    },
    login() {
      this.$emit("login", true);
    },
    imgOnerror(e) {
      let img = e.srcElement;
      img.src =
        "https://www.freepik.com/profile/accounts/avatar/default_06.png";
      img.onerror = null;
    },
  },
};
</script>

<style>
.navigation {
  background-color: #1d262d;
  position: relative;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.navigation .menus {
  display: flex;
  justify-content: flex-start;
}

.navigation .menus li {
  padding: 0 10px;
  list-style: none;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a5b7c6;
  position: relative;
}

.navigation .menus .userContent {
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
}
.navigation .menus .userContent:hover {
  color: #fff;
}
.navigation .menus li .userContent .avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}
.navigation .menus li .userContent .avatar img {
  width: 100%;
  height: 100%;
  display: block;
}

.navigation .menus li a {
  color: #a5b7c6;
  text-decoration: none;
}

.navigation .menus li a:hover {
  color: #fff;
}

.navigation .menus li .register {
  padding: 2px 10px;
  border: 1px solid #4a6375;
  border-radius: 3px;
  box-sizing: border-box;
}

.navigation .menus li .pricing {
  color: #fba707;
}

.navigation .menus li .pricing:hover {
  color: #e09404;
}

.showUserInfo {
  border-radius: 3px;
  background-color: #fff;
  width: 280px;
  position: absolute;
  top: 45px;
  left: 0px;
  z-index: 6;
  padding: 20px;
  box-shadow: 0 0 60px rgb(34 34 34 / 25%);
}

.showUserInfo::after {
  content: "";
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  top: -10px;
  left: 25px;
  height: 0;
  width: 0;
  border-bottom: 4px solid #fff;
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  margin: 6px 0 0;
}
.showUserInfo .userInfo {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: 10px;
}
.showUserInfo .userInfo .avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
}
.showUserInfo .userInfo .avatar img {
  width: 100%;
  height: 100%;
  display: block;
}
.showUserInfo .userInfo .info {
  overflow: hidden;
  padding-right: 10px;
}
.showUserInfo .userInfo .info span {
  display: block;
  color: #374957;
}
.showUserInfo .userInfo .info em {
  display: block;
  color: #5f7d95;
  font-size: 12px;
  font-style: normal;
  margin-bottom: 10px;
}
.showUserInfo .userInfo .info .editProfile {
  background-color: #1273eb;
  color: #fff;
  height: 34px;
  line-height: 34px;
  padding: 0 20px;
  border-radius: 3px;
  font-size: 14px;
  display: block;
}
.showUserInfo .userInfo .info .editProfile:hover {
  background-color: #0161cd;
}
.showUserInfo hr {
  height: 1px;
  margin: 0 0 10px;
  border: none;
  background-color: #cfd9e0;
}
.showUserInfo .list {
  overflow: hidden;
  margin-bottom: 10px;
}
.showUserInfo .list .item {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #27343e;
  font-size: 14px;
  height: 34px;
}
.showUserInfo .list .item.fl {
  justify-content: space-between;
}
.showUserInfo .list .item i {
  width: 14px;
  height: 14px;
  margin-left: 10px;
}
.showUserInfo .list .item .downCount {
  background-color: #869fb2;
  font-size: 10px;
  padding: 2px 4px;
  vertical-align: middle;
  border: 1px solid #869fb2;
  color: #fff;
  border-radius: 20px;
}
.nomb {
  margin-bottom: 0 !important;
}
</style>
