import request from '@/api/request'


//微信登陆
export function index(params) {
	return request({ url: '/sucai/index',method: 'post',data: params})
}

export function category(params) {
	return request({ url: '/sucai/category',method: 'post',data: params})
}


export function show(params) {
	return request({ url: '/sucai/show',method: 'post',data: params})
}


export function login(params) {
	return request({ url: '/sucai/login',method: 'post',data: params})
}